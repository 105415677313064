/*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */
/* Rem Unit font sizes with relative fallback http:/seesparkbox.com/foundry/scss_rem_mixin_now_with_a_better_fallback
	Usage: @include font-size(1, large);
*/
@media print {
  @page {
    size: 8.5in 11in;
    margin: 0 0 0.5in !important;
    orphans: 4;
    widows: 4;
  }
  *,
  *:before,
  *:after {
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  * {
    position: static !important;
  }
  body {
    background-color: #fff;
    font-size: 12pt;
    line-height: 1.2;
    color: #000;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  #menu {
    visibility: hidden;
  }
  .l-main {
    margin: 0.5in;
  }
  .l-main * {
    background: transparent !important;
  }
  h1,
  .alpha {
    font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 28pt;
    line-height: 32pt;
    color: #101820;
  }
  .paragraphs-item-text-block li,
  li {
    margin-bottom: 14pt;
  }
  h2,
  .beta {
    font-family: "minion-pro", Georgia, Times, "Times New Roman", serif;
    font-weight: bold;
    font-size: 21pt;
    line-height: 28pt;
    color: #002554;
  }
  h3,
  .gamma {
    font-size: 16pt;
    line-height: 21pt;
  }
  .gamma.regular {
    font-weight: normal;
  }
  h4,
  .delta {
    font-size: 14pt;
    line-height: 18pt;
    font-weight: 600;
  }
  h5,
  .epsilon,
  h6,
  .zeta {
    font-size: 14pt;
    line-height: 15pt;
    font-weight: 600;
  }
  h6,
  .zeta {
    font-weight: bold;
  }
  h2,
  .beta,
  h3,
  .gamma {
    font-weight: bold;
  }
  /* Text-Related Elements */
  p {
    margin: 0 0 14pt;
  }
  a,
  a:visited {
    text-decoration: none;
  }
  pre,
  blockquote {
    border: 0.5pt solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  select {
    background: #fff !important;
  }
  .navbar,
  .side-nav,
  header,
  footer {
    display: none;
  }
  .label {
    border: 1pt solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1pt solid #ddd !important;
  }
  th {
    text-align: left; /* LTR */
    color: #006;
    border-bottom: 1pt solid #ccc;
  }
  tr.odd {
    background-color: #ddd;
  }
  tr.even {
    background-color: #fff;
  }
  td {
    padding: 5pt;
  }
  .l-container,
  .layout-constrain > .content,
  .layout-constrain .group-header .content,
  .page-user .l-content-wrapper {
    width: 98%;
    max-width: 90%;
    margin: auto;
  }
  .screen-only {
    display: none;
  }
  .masonry-grid {
    height: auto !important;
    *zoom: 1;
    display: flex;
    flex-wrap: wrap;
  }
  .masonry-grid:before, .masonry-grid:after {
    content: " ";
    display: table;
    line-height: 0;
  }
  .masonry-grid:after {
    clear: both;
  }
  .masonry-grid:before, .masonry-grid:after {
    flex-basis: 0;
    order: 1;
  }
  .grid-item {
    *zoom: 1;
    flex: 1 0 auto;
    position: static !important;
    top: auto !important;
    left: auto !important;
    border: 1pt solid #8e97a0 !important;
    margin-bottom: 2%;
    padding: 12pt;
    float: left;
    width: 32%;
    margin-left: 2%;
  }
  .grid-item:before, .grid-item:after {
    content: " ";
    display: table;
    line-height: 0;
  }
  .grid-item:after {
    clear: both;
  }
  .grid-item:nth-child(3n+1) {
    margin-left: 0;
    clear: left;
  }
  .grid-item img {
    width: 25% !important;
    height: auto !important;
    float: left;
    margin-right: 5%;
  }
  .grid-item .b-text-wrapper {
    padding: 0;
    width: 70%;
    float: right;
  }
  .page-break {
    page-break-after: always;
  }
  .entity-paragraphs-item {
    margin-bottom: 14pt;
  }
  .entity-paragraphs-item .entity-paragraphs-item:last-child {
    margin-bottom: 0;
  }
  .entity-paragraphs-item .entity-paragraphs-item:last-child p {
    margin-bottom: 0 !important;
  }
  .layout,
  .layout > .content {
    *zoom: 1;
  }
  .layout:before, .layout:after,
  .layout > .content:before,
  .layout > .content:after {
    content: " ";
    display: table;
    line-height: 0;
  }
  .layout:after,
  .layout > .content:after {
    clear: both;
  }
  .layout > .col-sm-6,
  .layout > .content > .col-sm-6 {
    width: 48.5714285714%;
    float: left;
    margin-right: 2.8571428571%;
  }
  .layout > .col-sm-6:nth-child(2n),
  .layout > .content > .col-sm-6:nth-child(2n) {
    float: right;
    margin-right: 0;
  }
  .layout > .col-sm-6 .col-sm-8,
  .layout > .content > .col-sm-6 .col-sm-8 {
    width: 64.7058823529%;
    float: left;
    margin-right: 5.8823529412%;
  }
  .layout > .col-sm-6 .col-sm-4,
  .layout > .content > .col-sm-6 .col-sm-4 {
    width: 29.4117647059%;
    float: left;
    margin-right: 5.8823529412%;
  }
  .layout > .col-sm-9,
  .layout > .content > .col-sm-9 {
    width: 74.2857142857%;
    float: left;
    margin-right: 2.8571428571%;
  }
  .layout > .col-sm-3,
  .layout > .content > .col-sm-3 {
    width: 22.8571428571%;
    float: left;
    margin-right: 2.8571428571%;
  }
  .layout > .col-sm-8,
  .layout > .content > .col-sm-8 {
    width: 65.7142857143%;
    float: left;
    margin-right: 2.8571428571%;
  }
  .layout > .col-sm-4,
  .layout > .content > .col-sm-4 {
    width: 31.4285714286%;
    float: left;
    margin-right: 2.8571428571%;
  }
  .layout > .col-sm-5,
  .layout > .content > .col-sm-5 {
    width: 40%;
    float: left;
    margin-right: 2.8571428571%;
  }
  .layout > .col-sm-7,
  .layout > .content > .col-sm-7 {
    width: 57.1428571429%;
    float: left;
    margin-right: 2.8571428571%;
  }
  .layout .col.last,
  .layout > .content .col.last {
    float: right;
    margin-right: 0;
  }
  .panel-2col-stacked .panel-col-middle.has-two-col .panel-panel {
    width: 48.5714285714%;
    float: left;
    margin-right: 2.8571428571%;
  }
  .panel-2col-stacked .panel-col-middle.has-two-col .panel-panel.panel-col-last {
    float: right;
    margin-right: 0;
  }
  .pane-page-header .panel-2col-stacked .panel-col-middle.has-two-col .panel-panel, .pane-page-header .panel-2col-stacked .panel-col-middle.has-two-col .panel-panel.panel-panel {
    float: none;
    margin: 0;
    width: 100%;
  }
  .ds-2col-stacked-fluid .group-left,
  .ds-2col-stacked-fluid .group-right {
    width: 48.5714285714%;
    float: left;
    margin-right: 2.8571428571%;
  }
  .ds-2col-stacked-fluid .group-right {
    float: right;
    margin-right: 0;
  }
  .padding-extra-md {
    padding: 3em 0;
  }
  .spacing-md {
    margin: 2.25em 0;
  }
  .spacing-extra-md {
    margin: 3em 0;
  }
  .no-spacing-md {
    margin: 0;
  }
  .entity-paragraphs-item .layout .col {
    margin-bottom: 0;
  }
  .layout > .col-md.last,
  .layout > .content > .col-md.last {
    float: right;
    margin-right: 0;
  }
  .layout > .col-md-3,
  .layout > .content > .col-md-3 {
    width: 22.8571428571%;
    float: left;
    margin-right: 2.8571428571%;
  }
  .layout > .col-md-3.col-sm-6:nth-child(2n),
  .layout > .content > .col-md-3.col-sm-6:nth-child(2n) {
    width: 22.8571428571%;
    float: left;
    margin-right: 2.8571428571%;
  }
  .layout > .col-md-3.col-sm-6:nth-child(2n).last,
  .layout > .content > .col-md-3.col-sm-6:nth-child(2n).last {
    float: right;
    margin-right: 0;
  }
  .layout > .col-md-4,
  .layout > .content > .col-md-4 {
    width: 31.4285714286%;
    float: left;
    margin-right: 2.8571428571%;
  }
  .layout > .col-md-5,
  .layout > .content > .col-md-5 {
    width: 40%;
    float: left;
    margin-right: 2.8571428571%;
  }
  .layout > .col-md-6,
  .layout > .content > .col-md-6 {
    width: 48.5714285714%;
    float: left;
    margin-right: 2.8571428571%;
  }
  .layout > .col-md-7,
  .layout > .content > .col-md-7 {
    width: 57.1428571429%;
    float: left;
    margin-right: 2.8571428571%;
  }
  .layout > .col-md-8,
  .layout > .content > .col-md-8 {
    width: 65.7142857143%;
    float: left;
    margin-right: 2.8571428571%;
  }
  .layout > .col-md-9,
  .layout > .content > .col-md-9 {
    width: 74.2857142857%;
    float: left;
    margin-right: 2.8571428571%;
  }
  .col-md-9 .layout > .col-md-9,
  .col-md-9 .layout > .content > .col-md-9 {
    width: 65.3846153846%;
    float: left;
    margin-right: 3.8461538462%;
  }
  .layout > .col.last,
  .layout .col.last-md,
  .layout > .content > .col.last,
  .layout > .content .col.last-md {
    float: right;
    margin-right: 0;
  }
  .layout.four-column .col-fourth,
  .layout > .content.four-column .col-fourth {
    margin-right: 0;
  }
  .ds-3col-stacked-equal {
    *zoom: 1;
  }
  .ds-3col-stacked-equal:before, .ds-3col-stacked-equal:after {
    content: " ";
    display: table;
    line-height: 0;
  }
  .ds-3col-stacked-equal:after {
    clear: both;
  }
  .ds-3col-stacked-equal > .group-left,
  .ds-3col-stacked-equal > .group-middle,
  .ds-3col-stacked-equal > .group-right {
    width: 31.4285714286%;
    float: left;
    margin-right: 2.8571428571%;
    flex: 1 0 auto;
  }
  .ds-3col-stacked-equal > .group-right {
    float: right;
    margin-right: 0;
  }
  .node--book .entity-paragraphs-item .entity-paragraphs-item:last-child p {
    margin: 0;
  }
  .node--book .bg-image {
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }
  .node--book .bg-image img {
    width: 100%;
  }
  .node--book.book--cover {
    min-height: 11in;
    max-height: 11in;
    overflow: hidden;
  }
  .node--book.book--cover > .wrapper--title {
    padding: 0.25in 0.5in;
  }
  .node--book.book--cover > .wrapper--title img {
    max-width: 2.5in !important;
  }
  .node--book.book--cover > .wrapper--title .node__title {
    font-size: 34pt;
    line-height: 42pt;
  }
  .node--book.book--cover, .node--book.book--landing {
    page-break-after: always;
  }
  .node--book.book--cover > .wrapper--title *,
  .node--book.book--cover > .bg-image > .wrapper--title *, .node--book.book--landing > .wrapper--title *,
  .node--book.book--landing > .bg-image > .wrapper--title * {
    color: white !important;
  }
  .node--book.book--cover > .wrapper--title span,
  .node--book.book--cover > .bg-image > .wrapper--title span, .node--book.book--landing > .wrapper--title span,
  .node--book.book--landing > .bg-image > .wrapper--title span {
    font-size: 14pt;
    line-height: 28pt;
  }
  .node--book.book--cover > .wrapper--title h1,
  .node--book.book--cover > .wrapper--title h2,
  .node--book.book--cover > .bg-image > .wrapper--title h1,
  .node--book.book--cover > .bg-image > .wrapper--title h2, .node--book.book--landing > .wrapper--title h1,
  .node--book.book--landing > .wrapper--title h2,
  .node--book.book--landing > .bg-image > .wrapper--title h1,
  .node--book.book--landing > .bg-image > .wrapper--title h2 {
    margin-bottom: 0;
  }
  .node--book > .wrapper--book-node {
    margin: 0;
    margin-top: 0.25in;
  }
  .node--book.book--landing > .wrapper--title.has-image {
    max-height: 2.75in;
    overflow: hidden;
  }
  .node--book.book--landing > .wrapper--title.has-image .inner {
    height: 2.25in;
  }
  .node--book.book--landing > .wrapper--title.has-image .node__title {
    padding: 0.25in 0;
  }
  .node--book.book--sub-goal {
    margin: 0.25in auto;
  }
  .node--book.book--sub-goal h1 {
    font-size: 18pt;
    line-height: 21pt;
    font-weight: 600;
    font-family: "minion-pro", Georgia, Times, "Times New Roman", serif;
  }
  .book-export #node-328692 > .wrapper--book-node {
    margin-top: 0.375in;
  }
  .book-export #node-328692 .entity-paragraphs-item {
    margin-bottom: 14pt;
  }
  .book-export #node-328692 #paragraphs-item-2194 {
    margin-bottom: 0;
  }
  .book-export #node-328692 .entity-paragraphs-item.spacing-top {
    margin-top: 0;
  }
  .book-export #node-328692 h2 {
    font-size: 14pt;
    line-height: 20pt;
    margin-bottom: 0;
    font-family: "minion-pro", Georgia, Times, "Times New Roman", serif;
  }
  .book-export #node-328692 h2 span {
    font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 11pt;
    line-height: 14pt;
  }
  .book-export #node-328692 .paragraphs-item-text-block li {
    margin: 0 0 7pt 0.25in;
  }
  .book-export #node-328692 .gamma {
    font-size: 11pt;
    line-height: 14pt;
  }
  .book-export #node-328692.book--landing > .wrapper--title.has-image {
    max-height: 2.25in;
  }
  .book-export #node-328692 .paragraphs-item-embed-book-page h2,
  .book-export #node-328692 .paragraphs-item-embed-book-page h2 span {
    color: #002554 !important;
  }
  .book-export .fullpage > .wrapper--book-node > .entity > .group-header, .book-export .fullpage > .wrapper--book-node > .entity.vertically-centered > div {
    display: none;
  }
  .book-export .fullpage > .wrapper--book-node > .entity.vertically-centered > div.content {
    display: block;
  }
  .book-export .book--cover {
    background-size: contain;
    min-height: 825px;
  }
  .book-export .book--cover > .wrapper--title {
    background: #101820;
    padding: 1em;
    text-align: center;
  }
  .book-export .book--cover > .wrapper--title img {
    display: block;
    max-width: 200px !important;
    margin: auto;
  }
  .book-export .book--cover > .wrapper--title .node__title {
    text-align: center;
    font-size: 3.1875em;
  }
  .book-export .book--cover > .wrapper--title .node__title,
  .book-export .book--landing > .wrapper--title .node__title {
    color: white;
  }
  .book-export .book--landing > .wrapper--title.has-image {
    padding: 0;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    min-height: 200px;
  }
  .book-export .book--landing > .wrapper--title.has-image .inner {
    background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.46) 56%, #000);
    background-image: -o-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.46) 56%, #000);
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.46) 56%, #000);
    background-repeat: no-repeat;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00000000", endColorstr="#FF000000", GradientType=0);
    min-height: 200px;
  }
  .book-export .book--landing > .wrapper--title.has-image .node__title {
    padding: 0.75em auto;
  }
  .book-export .book--sub-goal {
    margin: 1.5em auto;
  }
  .book-export .book--sub-goal h1 {
    font-size: 0.74em;
  }
  .book-export #node-328692 > .wrapper--book-node .bg-blue {
    background: none;
    padding: 0;
  }
  .book-export #node-328692 > .wrapper--book-node .bg-blue * {
    color: #000;
  }
  .book-export #node-328692 > .wrapper--book-node .paragraphs-item-embed-book-page h2 {
    margin-bottom: 0;
    font-family: "minion-pro", Georgia, Times, "Times New Roman", serif;
  }
  .book-export #node-328692 > .wrapper--book-node .paragraphs-item-embed-book-page h2 span {
    font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
}