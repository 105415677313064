.node--book {
  .entity-paragraphs-item .entity-paragraphs-item:last-child p {
    margin: 0;
  }
  .bg-image {
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    img {
      width: 100%;
    }
  }

  &.book--cover {
    min-height: 11in;
    max-height: 11in;
    overflow: hidden;
    > .wrapper--title {
      padding: .25in .5in;
      img {
        max-width: 2.5in !important;
      }
      .node__title {
        font-size: 34pt;
        line-height: 42pt;
      }
    }
  }
  &.book--cover,
  &.book--landing {
    page-break-after: always;
    > .wrapper--title ,
    > .bg-image >.wrapper--title {
     * {
        color: white !important;
      }
      span {
        font-size: 14pt;
        line-height: 28pt;
      }
      h1,
      h2 {
        margin-bottom: 0;
      }
    }
  }
  > .wrapper--book-node {
    margin: 0;
    margin-top: .25in
  }
  &.book--landing {
    > .wrapper--title.has-image {
      max-height: 2.75in;
      overflow: hidden;
      .inner {
        height: 2.25in;
      }
      .node__title {
        padding: .25in 0;
      }
    }
  }
  &.book--sub-goal {
    h1 {
      font-size: 18pt;
      line-height: 21pt;
      font-weight: 600;
      font-family: $font-secondary;
    }
    margin: .25in auto;
  }
}

.book-export #node-328692 {
  > .wrapper--book-node {
    margin-top: .375in;
  }
  .entity-paragraphs-item {
    margin-bottom: 14pt;
  }
  #paragraphs-item-2194 {
    margin-bottom: 0;
  }
  .entity-paragraphs-item.spacing-top {
    margin-top: 0;
  }
  h2 {
    font-size: 14pt;
    line-height: 20pt;
    margin-bottom: 0;
    font-family: $font-secondary;
    span {
    }
    span {
      font-family: $font;

      font-size: 11pt;
      line-height: 14pt;
    }
  }

  .paragraphs-item-text-block li {
    margin: 0 0 7pt .25in;
  }
  .gamma {
    font-size: 11pt;
    line-height: 14pt;
  }

  &.book--landing {
    > .wrapper--title.has-image {
      max-height: 2.25in;
    }
  }
  .paragraphs-item-embed-book-page {

    h2,
    h2 span {
      color: $blue-darkest !important;
    }
  }

}

.book-export {



  .fullpage > .wrapper--book-node > .entity {
    > .group-header,
    &.vertically-centered > div {
      display: none;
    }
    &.vertically-centered > div.content {
      display: block;
    }
  }

  .book--cover {
    background-size: contain;
    min-height: 825px;
    > .wrapper--title {
      background: $gray-darkest;
      padding: 1em;
      text-align: center;
      img {
        display: block;
        max-width: 200px !important;
        margin: auto;
      }
      .node__title {
        text-align: center;
        font-size: em($font-size-h1-alt);
      }
    }
  }
  .book--cover,
  .book--landing {
    > .wrapper--title .node__title {
      color: white;
    }
  }

  .book--landing {
    > .wrapper--title.has-image {
      padding: 0;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      min-height: 200px;
      .inner {
        @include gradient-vertical-three-colors(rgba(0, 0, 0, 0), rgba(0, 0, 0, .46), 56%, #000);
        min-height: 200px;
      }
      .node__title {
        padding: em($space-half) auto;
      }
    }
  }

  .book--sub-goal {
    h1 {
      font-size: em($font-size-h2, $font-size-h1);
    }
    margin: em($space) auto;
  }
  #node-328692 > .wrapper--book-node {
    .bg-blue {
      background: none;
      padding: 0;
      * {
        color: #000;
      }
    }
    .paragraphs-item-embed-book-page {
      h2 {
        margin-bottom: 0;
        font-family: $font-secondary;
        span {
          font-family: $font;
        }
      }
    }
  }
}
