@page {
  size: 8.5in 11in;
  margin: 0 0 0.5in !important;
  orphans: 4;
  widows: 4;
}
*,
*:before,
*:after {
  color: #000 !important; // Black prints faster: h5bp.com/s
  box-shadow: none !important;
  text-shadow: none !important;
}
* {
  position: static !important;
}

body {
  background-color: #fff;
  font-size: 12pt;
  line-height: 1.2;
  color: #000;
  width: 100%;
  margin: 0;
  padding: 0;
}

#menu {
  visibility: hidden;
}
.l-main {
  margin: 0.5in;
  * {
    background: transparent !important;
  }
}

// TODO: Replace missing Headings mixin / function
// #{headings()} {
//   a {
//     color: #000;
//   }
//   line-height: 1.2;
//   margin-bottom: 14pt;
// }

h1,
.alpha {
  font-family: $font;
  font-size: 28pt;
  line-height: 32pt;
  color: $header-color;
}
.paragraphs-item-text-block li,
li {
  margin-bottom: 14pt;
}

h2,
.beta {
  font-family: $font-secondary;
  //@include font-smoothing();
  font-weight: bold;
  font-size: 21pt;
  line-height: 28pt;
  color: $blue-darkest;
}

h3,
.gamma {
  // font-family: $font-secondary;
  font-size: 16pt;
  line-height: 21pt;
}
.gamma.regular {
  font-weight: normal;
}

h4,
.delta {
  font-size: 14pt;
  line-height: 18pt;
  font-weight: 600;
}

h5,
.epsilon,
h6,
.zeta {
  font-size: 14pt;
  line-height: 15pt;
  font-weight: 600;
}

h6,
.zeta {
  font-weight: bold;
}

h2,
.beta,
h3,
.gamma {
  //@include font-smoothing();
  font-weight: bold;
}

/* Text-Related Elements */
p {
  margin: 0 0 14pt;
}
a,
a:visited {
  text-decoration: none;
}

pre,
blockquote {
  border: 0.5pt solid #999;
  page-break-inside: avoid;
}

thead {
  display: table-header-group; // h5bp.com/t
}

tr,
img {
  page-break-inside: avoid;
}

img {
  max-width: 100% !important;
}

p,
h2,
h3 {
  orphans: 3;
  widows: 3;
}

h2,
h3 {
  page-break-after: avoid;
}

// Bootstrap specific changes start
//
// Chrome (OSX) fix for https://github.com/twbs/bootstrap/issues/11245
// Once fixed, we can just straight up remove this.
select {
  background: #fff !important;
}

// Bootstrap components
.navbar,
.side-nav,
header,
footer {
  display: none;
}

.label {
  border: 1pt solid #000;
}

.table {
  border-collapse: collapse !important;
}
.table-bordered {
  th,
  td {
    border: 1pt solid #ddd !important;
  }
}

th {
  text-align: left; /* LTR */
  color: #006;
  border-bottom: 1pt solid #ccc;
}
tr.odd {
  background-color: #ddd;
}
tr.even {
  background-color: #fff;
}
td {
  padding: 5pt;
}

.l-container,
.layout-constrain > .content,
.layout-constrain .group-header .content,
.page-user .l-content-wrapper {
  width: 98%;
  max-width: 90%;
  margin: auto;
}
.screen-only {
  display: none;
}
.masonry-grid {
  height: auto !important;
  @include clearfix;
  &:before,
  &:after {
    flex-basis: 0;
    order: 1;
  }
  display: flex;
  flex-wrap: wrap;
}
.grid-item {
  @include clearfix;
  flex: 1 0 auto;
  position: static !important;
  top: auto !important;
  left: auto !important;
  border: 1pt solid #8e97a0 !important;
  margin-bottom: 2%;
  padding: 12pt;
  float: left;
  width: 32%;
  margin-left: 2%;
  &:nth-child(3n + 1) {
    margin-left: 0;
    clear: left;
  }
  img {
    width: 25% !important;
    height: auto !important;
    float: left;
    margin-right: 5%;
  }
  .b-text-wrapper {
    padding: 0;
    width: 70%;
    float: right;
  }
}
.page-break {
  page-break-after: always;
}

.entity-paragraphs-item {
  margin-bottom: 14pt;
  .entity-paragraphs-item:last-child {
    margin-bottom: 0;
  }
}
.entity-paragraphs-item .entity-paragraphs-item:last-child p {
  margin-bottom: 0 !important;
}

.layout,
.layout > .content {
  @include clearfix;
  > .col-sm-6 {
    &:nth-child(2n) {
      @include last;
    }
    @include span(6);

    .col-sm-8 {
      @include span(4 of 6);
    }
    .col-sm-4 {
      @include span(2 of 6);
    }
  }
  > .col-sm-9 {
    @include span(9);
  }
  > .col-sm-3 {
    @include span(3);
  }
  > .col-sm-8 {
    @include span(8);
  }
  > .col-sm-4 {
    @include span(4);
  }
  > .col-sm-5 {
    @include span(5);
  }
  > .col-sm-7 {
    @include span(7);
  }
  .col.last {
    @include last;
  }
}
.panel-2col-stacked .panel-col-middle.has-two-col {
  .panel-panel {
    @include span(6);
    &.panel-col-last {
      @include last;
    }
    .pane-page-header & {
      &,
      &.panel-panel {
        float: none;
        margin: 0;
        width: 100%;
      }
    }
  }
}
.ds-2col-stacked-fluid {
  .group-left,
  .group-right {
    @include span(6);
  }
  .group-right {
    @include last;
  }
}

.padding-extra-md {
  padding: em($space-double) 0;
}

.spacing-md {
  margin: em($space-and-half) 0;
}

.spacing-extra-md {
  margin: em($space-double) 0;
}

.no-spacing-md {
  margin: 0;
}

.entity-paragraphs-item .layout .col {
  margin-bottom: 0;
}
.layout,
.layout > .content {
  > .col-md.last {
    @include last;
  }
  > .col-md-3 {
    @include span(3);
    &.col-sm-6:nth-child(2n) {
      @include span(3);
      &.last {
        @include last;
      }
    }
  }
  > .col-md-4 {
    @include span(4);
  }
  > .col-md-5 {
    @include span(5);
  }
  > .col-md-6 {
    @include span(6);
  }
  > .col-md-7 {
    @include span(7);
  }
  > .col-md-8 {
    @include span(8);
  }
  > .col-md-9 {
    @include span(9);
    .col-md-9 & {
      @include span(6 of 9);
    }
  }
  > .col.last,
  .col.last-md {
    @include last;
  }
  &.four-column {
    .col-fourth {
      margin-right: 0;
    }
  }
}
.ds-3col-stacked-equal {
  @include clearfix;

  > .group-left,
  > .group-middle,
  > .group-right {
    @include span(4);
    flex: 1 0 auto;
  }
  > .group-right {
    @include last;
  }
}
